import { ArticleViewProps } from '@roc-digital/ui-lib';
import { JumbotronHeadline } from './JumbotronHeadline';
import { Landscape } from './Landscape';
import { Portrait } from './Portrait';
import { Mini } from './Mini';
import { ActionBar } from './ActionBar';

export function ArticleView(props: ArticleViewProps) {

  const display = props.orientation || 'portrait';

  if(!props.article) {
    return <></>;
  }

  if(display === 'title-block' || display === 'jumbo'){
    return <JumbotronHeadline {...props}/>
  } else if(display === 'landscape') {
    return <Landscape {...props}/>
  } else if(display === 'portrait') {
    return <Portrait {...props}/>
  } else if(display === 'mini') {
    return <Mini {...props}/>
  } else if(display === 'action-bar') {
    return <ActionBar
      bookmarked={props.article.bookmarked}
      voted={props.article.voted}
      upvotes={props.article.upvotes || 0}
      downvotes={props.article.downvotes || 0}
      articleId={props.article.id || ''}
      comments={props.article.comments}
      inlineComments={props.inlineComments}
      onAction={props.onAction || (() => {})}
    />
  } else {
    return <Mini {...props}/>
  }
}
