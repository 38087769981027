import { Button, ButtonSizes, ButtonVariants, Heading1, Heading2, Input, Text } from "@roc-digital/ui-web";
import React from "react";

interface EditModalProps {
  body: string;
  onSave: (body: string) => void;
  onCancel: () => void;
  onDelete: () => void;
}

export function EditModal(props: EditModalProps) {
  const [body, setBody] = React.useState(props.body);
  const canSave = body !== props.body && body?.trim()?.length > 0;

  return <Container title="Edit Comment">
    <Input value={body} changeEvent={setBody} className="dark:bg-white dark:text-black w-full"/>
    <div className="flex flex-row" style={{gap: 4}}>
      <Button
        variant={ButtonVariants.destructive}
        size={ButtonSizes.small}
        clickEvent={props.onDelete}>Delete</Button>
      <div className="flex-grow"></div>
      <Button
        variant={ButtonVariants.secondary}
        size={ButtonSizes.small}
        clickEvent={props.onCancel}>Cancel</Button>
      <Button
        disabled={canSave !== true}
        variant={ButtonVariants.primary}
        size={ButtonSizes.small}
        clickEvent={() => props.onSave(body)}
      >Save</Button>
    </div>
  </Container>
}

interface ConfirmDeleteModalProps {
  onConfirm: () => void;
  onCancel: () => void;
}
export function ConfirmDeleteModal(props: ConfirmDeleteModalProps) {
  return <Container title="Delete Comment">
    <Text>
      Delete this comment?
    </Text>
    <div className="flex flex-row" style={{gap: 4}}>
      <Button
        variant={ButtonVariants.destructive}
        size={ButtonSizes.small}
        clickEvent={props.onConfirm}>Yes, Delete</Button>
      <div className="flex-grow"></div>
      <Button
        variant={ButtonVariants.secondary}
        size={ButtonSizes.small}
        clickEvent={props.onCancel}>Cancel</Button>
    </div>
  </Container>
}

interface ContainerProps {
  title: string;
  children: any;
}

function Container(props: ContainerProps) {
  return <div className="p-4 dark:bg-black dark:text-white gap-3 flex flex-col">
  <div className="text-xl w-full font-bold mb-2">{props.title}</div>
    {props.children}
  </div>;
}

