import { Icon } from "@roc-digital/ui-web";
import {
  usersIcon
} from '@roc-digital/ui-lib';
import { Comment } from "@roc-digital/types";
import React from "react";

interface CommentItemProps {
  comment: Comment;
  onEdit: (comment: Comment) => void;
}

export function CommentItem(props: CommentItemProps) {

  const date = React.useMemo(() => {
    return new Date(props.comment.timestamp * 1000).toLocaleString();
  }, [props.comment.timestamp])
  
  return <div className="flex flex-row gap-2" style={{
    backgroundColor: props.comment.error ? '#ffe7e7' : 'unset',
    padding: props.comment.error ? '8px' : 'unset',
  }}>
    <div>
      <Icon src={usersIcon} className="w-[36px] h-[36px] min-w-[36px] min-h-[36px]"/>
    </div>
    <div className="flex flex-col">
      <div className="font-bold text-[12px] text-[#545454]">{props.comment.handle}</div>
      <div className="font-medium text-[12px] text-[#545454]">
        {props.comment.body}
      </div>
      {props.comment?.error ? (
        <div className="font-bold text-[12px] text-[#545454]">
          {props.comment.error}
        </div>
      ) : (
        <div className="flex flex-row" style={{gap: 4}}>
        <div  className="font-medium text-[10px] text-[#C6C6C6]">{date}</div>
         {props.comment?.can_edit ? <>
          <div  className="font-medium text-[10px] text-[#C6C6C6]">-</div>
          <span onClick={() => props.onEdit(props.comment)} className="underline text-[10px] hover:cursor-pointer text-[#24AAE1]">Edit</span>
         </> : null}
        </div>
      )}
    </div>
  </div>
}